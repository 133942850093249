import React from 'react';
import Header from '../Header/Header';

const About = () => {
    return (
        <div>
            <Header></Header>
            <h5>This is the true Real About me page</h5>
        </div>
    );
};

export default About;