import React from 'react';

const Home = () => {
    return (
        <div>
            <h3>This is real home components</h3>
        </div>
    );
};

export default Home;